import React from 'react'
import MemphisPattern from '@components/MemphisPattern'
import HeroBoxed from '@widgets/HeroBoxed'
import Content from './ServiceCard.Content'
import Photo from './ServiceCard.Photo'
import PropTypes from 'prop-types'

const ServiceCard = ({...props}) => {
  return (
    <HeroBoxed.Wrapper>
      <HeroBoxed.RightColumn>
        <Photo {...props} />
      </HeroBoxed.RightColumn>
      <HeroBoxed.LeftColumn>
        <Content {...props} />
      </HeroBoxed.LeftColumn>
      <MemphisPattern />
    </HeroBoxed.Wrapper>
  )
}

export default ServiceCard

