import React from 'react'
import { ReactSVG } from 'react-svg'
import { graphql, useStaticQuery } from 'gatsby'
import { useColorMode, Box } from 'theme-ui'

const styles = {
  styleSvg: {
    '& > div div svg': {
      width: '100%'
    }
  }
}


  

export default ({image}) => {
  //const data = useStaticQuery(serviceCardQuery)
  //const { publicURL } = (data && data.file) || {}
  const { serviceSvgs } = useStaticQuery(serviceCardQuery)
  const images = serviceSvgs.edges
  
  const [colorMode] = useColorMode()
  const isDark = colorMode === `dark`

  const imageName = isDark && images.map(item => item.node.name).includes(image+"-dark") ? image + "-dark" : image
  //if (!publicURL) return ''

  return (
    (
      <>
      <Box sx={styles.styleSvg}>
          {images.map((item, i) => {
              return item.node.name.endsWith(imageName) ? <ReactSVG src={item.node.publicURL} /> : ""
          })}
      </Box>
      </>
    )
  )
}

const serviceCardQuery = graphql`
query serviceCardQuery {
  serviceSvgs: allFile(
    filter: {sourceInstanceName: {eq: "asset"}, absolutePath: {regex: "/services/([a-z-]*)(.)(svg)/"}}
    sort: {fields: name, order: DESC}
  ) {
    edges {
      node {
        publicURL
        name
      }
    }
  }
}
`
