import React from 'react'
import { Link } from 'gatsby'
import {Trans, useTranslation} from 'gatsby-plugin-react-i18next'
import { Heading, Box, Button, IconButton, NavLink } from 'theme-ui'
import { FaPhone, FaEnvelope, FaLocationArrow } from 'react-icons/fa'
import { BiMailSend} from 'react-icons/bi'
import { FiPhone } from 'react-icons/fi'

/**
 * Shadow me to add your own content
 */

const styles = {
  wrapper: {
    ml: 3
  },
  heading: {
    color: `alpha`
  },
  running: {
    color: `omegaDark`,
    fontWeight: `body`
  },
  contactButton: {
    fontWeight: 'normal',
    borderRadius: '8px',
    mr: '1rem',
    ml: '0 !important',
    mt: '1rem !important',
    'svg': {
      width: '1.4rem',
      height: '1.4rem',
      mr: 2
    },
    'a': {
      fontWeight: `inherit`,
      fontSize: '1.2rem'
    }
  }
}

export default ({title, subheader, description}) => (
  <Box sx={styles.wrapper}>
    <Heading variant='h1' sx={styles.heading}>
      {title}
    </Heading>
    <Heading variant='h2' sx={styles.running}>
      {subheader}
    </Heading>
    <Heading variant='h3' sx={styles.running}>{description}</Heading>
    <Heading variant='h3'><Trans>We are at your service.</Trans></Heading>
    <Box variant='buttons.group'>
      {
      <Button variant='white' as={Link} sx={styles.contactButton} >
        <FiPhone />
        <NavLink href="tel:01633717190" >
        +49 (0)163 3717 190 
        </NavLink>
      </Button>
      }
      <Button variant='white' as={Link} to='/contact' sx={styles.contactButton}>
        <BiMailSend />
        <NavLink>
        <Trans>Get In Touch</Trans>
        </NavLink>
      </Button>
    </Box>
  </Box>
)
