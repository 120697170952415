import React from 'react'
import {graphql} from 'gatsby'
import {Trans, useTranslation} from 'gatsby-plugin-react-i18next'
import {Container, Box, Message, useColorMode, Card } from 'theme-ui'
import { Main } from '@layout'
import Divider from '@components/Divider'
import CardList from '@components/CardList'
import 'react-vertical-timeline-component/style.min.css'

import ServiceCard from '../../flow-ui-widgets/ServiceCard/ServiceCard'

const styles = {
    cardsSmall: {
        ml: "auto"
    }
}

const ServicesList = ({detailed}) => {
    const [colorMode] = useColorMode()
    const isDark = colorMode === `dark`
    const { t } = useTranslation()
    const services = [
        {
            imageName: "ui-ux",
            title: "UI & UX",
            subheader: "Design and User Experience",
            description: "Design is the language that communicates your best idea for your product/service to your cleints. Let us walk with you through the journey of creating a design that speaks to your passion for your product. Learn how your clients interact with your product/service and leave your clients feeling happy by focusing on improving the things that deliver a great experience for your clients."
        },
        {
            imageName: "app-development",
            title: "Web & App Development",
            subheader: "System and Software Development",
            description: "We develop cutting-edge technology solutions with special focus on reliability, scalability, and security. If you are looking to develop a website, web front-end or back-end application, mobile application or custom system application, we work with your to design and develop the product that meets your needs. With modern tools and methods, we organize and manage development projects; delivering on time and on budget."
        },
        {
            imageName: "cloud",
            title: "Cloud Services",
            subheader: "Cloud Infrastructure and Dev Ops, Amazon AWS, Microsoft Azure",
            description: "If you are getting stuck managing your IT services manually, experiencing long delivery times, your IT services are slowing down, ..., then it is time to automate these services and build a robust modern cloud infrastructure.  Reliability, stability, scalability, and time to market are important factors for delivering an optimal service to your clients and staying ahead of the competition."
        }
    ]
    
    return detailed ? (
        <Main>
            {services.map((item, i) => {
                return (
                    <>
                        <ServiceCard 
                        image = {item.imageName}
                        title = {t(item.title)}
                        subheader = {t(item.subheader)}
                        description = {t(item.description)} />
                        <Divider />
                    </>
                )
            })}
        </Main>
    ) : (
        <Box sx={styles.cardsSmall}>
            <CardList
                nodes={services}
                limit={3}
                columns={[1, 1, 2, 3]}
                variant={[
                'horizontal-md',
                'horizontal',
                'horizontal',
                'vertical'
                ]}
                omitCategory
                omitExcerpt
            />
            <Divider space={2} />
        </Box>
    )
}

export default ServicesList


const serviceCardQuery = graphql`
query serviceCardQuery {
  serviceSvgs: allFile(
    filter: {sourceInstanceName: {eq: "asset"}, absolutePath: {regex: "/services/([a-z-]*)(.)(svg)/"}}
    sort: {fields: name, order: DESC}
  ) {
    edges {
      node {
        publicURL
        name
      }
    }
  }
}
`